<template>
  <VCard>
    <VCardTitle>
      Загрузка картинки
    </VCardTitle>

    <VCardText>
      <VForm>
        <VContainer fluid>
          <VRow align="center">
            <VCol>
              <VAutocomplete
                :loading="loading"
                :value="type"
                :items="types"
                item-text="title"
                item-value="value"
                label="Тип"
                @input="$emit('update:type', $event)"
              />
            </VCol>
          </VRow>

          <VRow align="center">
            <VCol>
              <VTextField
                :loading="loading"
                :value="uuid"
                label="UUID"
                @input="$emit('update:uuid', $event)"
              />
            </VCol>
          </VRow>

          <VRow align="center">
            <VCol>
              <VTextField
                :loading="loading"
                :value="name"
                label="Имя"
                @input="$emit('update:name', $event)"
              />
            </VCol>
          </VRow>

          <VRow align="center">
            <VCol>
              <VFileInput
                :loading="loading"
                :value="image"
                label="Картинка"
                @change="$emit('update:image', $event)"
              />
            </VCol>
          </VRow>
        </VContainer>
      </VForm>
    </VCardText>

    <VCardActions>
      <VSpacer />

      <VBtn
        text
        @click="handleCancel"
      >
        Отмена
      </VBtn>

      <VBtn
        color="primary"
        text
        :loading="loading"
        :disabled="!filled"
        @click="handleSubmit"
      >
        Загрузить
      </VBtn>
    </VCardActions>
  </VCard>
</template>

<script>
import { TYPES_LIST } from './common';

export default {
  name: 'ImageForm',

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    type: {
      type: String,
      required: true,
    },

    uuid: {
      type: String,
      required: true,
    },

    name: {
      type: String,
      required: true,
    },

    image: {
      type: [String, File],
      default: '',
    },

    types: {
      type: Array,
      default: () => TYPES_LIST,
    },
  },

  computed: {
    filled() {
      return this.type && this.uuid && this.name && this.image;
    },
  },

  methods: {
    handleCancel() {
      this.$emit('cancel');
    },

    handleSubmit() {
      this.$emit('submit');
    },
  },
};
</script>

export const TYPE_COMPANY = 'company';
export const TYPE_USER = 'user';
export const TYPE_COURSE = 'course';
export const TYPE_SKILL = 'skill';
export const TYPE_LESSON = 'lesson';
export const TYPE_ANSWER = 'answer';
export const TYPE_QUESTION = 'question';
export const TYPE_CATALOGUE = 'catalogue';
export const TYPES = [TYPE_COMPANY, TYPE_USER,
  TYPE_COURSE, TYPE_SKILL,
  TYPE_LESSON, TYPE_ANSWER,
  TYPE_QUESTION, TYPE_CATALOGUE];
export const TYPES_LIST = [
  {
    id: 1,
    title: 'Компания',
    value: TYPE_COMPANY,
  },
  {
    id: 2,
    title: 'Пользователь',
    value: TYPE_USER,
  },
  {
    id: 3,
    title: 'Курс',
    value: TYPE_COURSE,
  },
  {
    id: 4,
    title: 'Навык',
    value: TYPE_SKILL,
  },
  {
    id: 5,
    title: 'Урок',
    value: TYPE_LESSON,
  },
  {
    id: 6,
    title: 'Вопрос',
    value: TYPE_QUESTION,
  },
  {
    id: 7,
    title: 'Ответ',
    value: TYPE_ANSWER,
  },
  {
    id: 8,
    title: 'Каталог',
    value: TYPE_CATALOGUE,
  },
];

export const NAME_AVATAR = 'avatar';
export const NAME_LOGO = 'logo';
export const NAME_COVER = 'cover';
export const NAME_UNIVERSAL_COVER = 'universal_cover';
export const NAME_ICON = 'icon';
export const NAME_IMAGE = 'image';
export const NAMES = [NAME_AVATAR, NAME_LOGO, NAME_COVER, NAME_UNIVERSAL_COVER, NAME_ICON, NAME_IMAGE];

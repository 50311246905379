<template>
  <TTView>
    <VRow>
      <VCol>
        <VAlert
          colored-border
          border="left"
          type="info"
          elevation="1"
        >
          Типы и имена картинок [<a
            href="https://burning-heart.atlassian.net/wiki/spaces/TT/pages/1231749896"
            target="_blank"
          >Confluence</a>]
        </VAlert>
      </VCol>
    </VRow>

    <VRow>
      <VCol>
        <VAlert
          colored-border
          border="left"
          type="warning"
          elevation="1"
        >
          <ul>
            <li>формат: png, jpeg, gif</li>
            <li>размер: не более 10 Mb</li>
          </ul>
        </VAlert>
      </VCol>
    </VRow>

    <VRow>
      <VCol>
        <VChipGroup column>
          <VChip @click="setUserAvatarPreset">
            Аватар пользователя
          </VChip>

          <VChip @click="setCompanyLogoPreset">
            Логотип компании
          </VChip>

          <VChip @click="setCourseCoverPreset">
            Обложка курса
          </VChip>

          <VChip @click="setCourseUniversalCoverPreset">
            Обложка всех уроков в курсе
          </VChip>

          <VChip @click="setSkillCoverPreset">
            Обложка навыка
          </VChip>

          <VChip @click="setSkillIconPreset">
            Иконка навыка
          </VChip>

          <VChip @click="setLessonCoverPreset">
            Обложка урока
          </VChip>

          <VChip @click="setQuestionPreset">
            Обложка вопроса теста
          </VChip>

          <VChip @click="setAnswerPreset">
            Обложка ответа теста
          </VChip>
        </VChipGroup>
      </VCol>
    </VRow>

    <VRow>
      <VCol>
        <ImageForm
          :loading="loading"
          :type="type"
          :uuid="uuid"
          :name="name"
          :image="image"
          @update:type="type = $event"
          @update:uuid="uuid = $event"
          @update:name="name = $event"
          @update:image="image = $event"
          @submit="handleSubmit"
          @cancel="handleCancel"
        />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import * as GoghaUploadConstants from '../../components/gogha/common';
import ImageForm from '../../components/gogha/ImageForm.vue';

export default {
  name: 'GoghaUpload',

  components: {
    ImageForm,
  },

  data() {
    return {
      loading: false,
      type: '',
      uuid: '',
      name: '',
      image: null,
    };
  },

  methods: {
    reset() {
      this.type = '';
      this.uuid = '';
      this.name = '';
      this.image = null;
    },

    async handleSubmit() {
      try {
        this.loading = true;

        const {
          type, uuid, name, image,
        } = this;

        const data = new FormData();
        data.append('image', image);

        await this.$di.api.Gogha.UploadImage(type, uuid, name, data);

        this.$di.notify.snackSuccess('Картинка успешно загружена');
      } catch (err) {
        this.$di.notify.snackError('Ошибка загрузки. Изображение не соответствует требуемому формату');
      } finally {
        this.loading = false;
      }
    },

    handleCancel() {
      this.reset();
    },

    setUserAvatarPreset() {
      this.type = GoghaUploadConstants.TYPE_USER;
      this.name = GoghaUploadConstants.NAME_AVATAR;
    },

    setCompanyLogoPreset() {
      this.type = GoghaUploadConstants.TYPE_COMPANY;
      this.name = GoghaUploadConstants.NAME_LOGO;
    },

    setCourseCoverPreset() {
      this.type = GoghaUploadConstants.TYPE_COURSE;
      this.name = GoghaUploadConstants.NAME_COVER;
    },

    setCourseUniversalCoverPreset() {
      this.type = GoghaUploadConstants.TYPE_COURSE;
      this.name = GoghaUploadConstants.NAME_UNIVERSAL_COVER;
    },

    setSkillCoverPreset() {
      this.type = GoghaUploadConstants.TYPE_SKILL;
      this.name = GoghaUploadConstants.NAME_COVER;
    },

    setSkillIconPreset() {
      this.type = GoghaUploadConstants.TYPE_SKILL;
      this.name = GoghaUploadConstants.NAME_ICON;
    },

    setLessonCoverPreset() {
      this.type = GoghaUploadConstants.TYPE_LESSON;
      this.name = GoghaUploadConstants.NAME_COVER;
    },

    setQuestionPreset() {
      this.type = GoghaUploadConstants.TYPE_QUESTION;
      this.name = GoghaUploadConstants.NAME_IMAGE;
    },

    setAnswerPreset() {
      this.type = GoghaUploadConstants.TYPE_ANSWER;
      this.name = GoghaUploadConstants.NAME_IMAGE;
    },
  },
};
</script>
